import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MessageType } from '../constants/enums';
import { MESSAGE_STORE } from '../constants/constants';

type MessageStore = {
    message: string | undefined;
    messageType: MessageType;
};

export const messageStore = createSlice({
    name: MESSAGE_STORE,
    initialState: {
        message: undefined,
        messageType: MessageType.Warning,
    } as MessageStore,
    reducers: {
        setMessage: (
            state,
            action: PayloadAction<{ message: string; messageType: MessageType }>
        ) => {
            state.message = action.payload.message;
            state.messageType = action.payload.messageType;
        },
        closeMessage: (state) => {
            state.message = undefined;
        },
    },
});

export const messageActions = messageStore.actions;
