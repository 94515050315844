import { FaThumbsDown, FaThumbsUp } from 'react-icons/fa6';
import { EventFunction } from '../../constants/types';

type VoteProps = {
    upVotes: number;
    downVotes: number;
    onUpVote: EventFunction;
    onDownVote: EventFunction;
};

export function Vote({ upVotes, downVotes, onUpVote, onDownVote }: VoteProps) {
    return (
        <div hidden={true} className="flex flex-row">
            <div>
                <span>{downVotes}</span>
                <FaThumbsDown className="px-2" onClick={onUpVote} />
            </div>
            <div>
                <span>{upVotes}</span>
                <FaThumbsUp className="px-2" onClick={onDownVote} />
            </div>
        </div>
    );
}
