import { Card } from '../../../../components/card/card';
import { Place } from '../../../../constants/entities.types';
import { getDistanceAway } from '../../../../utils/place.util';
import { Button, ButtonClassName } from '../../../../components/button/button';
import { navigateToOpenPlayTimes } from '../../../../utils/route.utils';
import { useNavigate } from 'react-router';
import { OpenPlayDays } from './open-play-days';
import { Vote } from '../../../../components/vote/vote';

type PlaceProps = {
    place: Place;
    latitude: number;
    longitude: number;
};

export function PlaceComponent({ place, latitude, longitude }: PlaceProps) {
    const navigate = useNavigate();

    const distanceAway = getDistanceAway(place, { latitude, longitude });
    const { costTypes, facilityTypes, upVotes, downVotes, name, openPlayDays } = place;

    function handleOpenPlayTimesPressed() {
        navigateToOpenPlayTimes(navigate, place);
    }

    return (
        <Card className="mb-4">
            <div className="align-items flex w-full flex-row flex-wrap p-1">
                <div className="flex flex-col">
                    <h1 className="text-xl font-medium">{name}</h1>
                    <div className="flex flex-row">
                        <div className="mr-4">{distanceAway.toFixed(1)} miles away</div>
                        <div className="mr-4">{costTypes.join(' and ')}</div>
                        <div className="mr-4">{facilityTypes.join(' and ')}</div>
                    </div>
                </div>
                <div className="flex flex-1 flex-row flex-wrap items-center justify-end">
                    <Vote
                        upVotes={upVotes}
                        downVotes={downVotes}
                        onUpVote={() => {}}
                        onDownVote={() => {}}
                    />
                    <OpenPlayDays openPlayDays={openPlayDays} />
                    <Button
                        className={`${ButtonClassName.Primary} ml-8`}
                        onClick={handleOpenPlayTimesPressed}
                    >
                        See Times
                    </Button>
                </div>
            </div>
        </Card>
    );
}
