import { MutableRefObject } from 'react';

type InputProps = React.InputHTMLAttributes<HTMLTextAreaElement> & {
    inputRef?: MutableRefObject<HTMLTextAreaElement>;
    handleOnChange?: (val) => any;
};

export const Textarea = ({
    className = '',
    handleOnChange,
    value,
    placeholder,
    inputRef,
    ...props
}: InputProps) => {
    const onChange = (event) => {
        handleOnChange?.(event.target.value);
    };

    return (
        <textarea
            className={`w-full rounded-lg border bg-input-color p-2.5 ${className}`}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            ref={inputRef}
            {...props}
        />
    );
};
