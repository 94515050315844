import { ACTIVITY_TO_FACILITY_NAME } from '../constants/constants';
import { Activity } from '../constants/enums';

export const toTitleCase = (str: string) => {
    if (str.length === 1) {
        str.charAt(0).toUpperCase();
    }

    return str.charAt(0).toUpperCase() + str.substring(1);
};

export const getFacilityName = (activity: Activity) => {
    const facilityName = ACTIVITY_TO_FACILITY_NAME[activity];
    return toTitleCase(facilityName);
};

export const searchString = (searchString, values: string[]): boolean => {
    if (['', undefined, null].includes(searchString)) {
        return true;
    }

    return values.some((val) => val.toLowerCase().includes(searchString.toLowerCase()));
};

export const padLeadingZero = (num: number) => {
    if (num < 10) {
        return `0${num}`;
    }
    return num;
};
