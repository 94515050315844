import { fifteenMinuteSliceToTime } from '../../../utils/date.util';
import { TIME_SLOTS } from '../calendar.constants';

export function TimeScale() {
    return (
        <div className="time-scale">
            {TIME_SLOTS.map((index, i) => {
                return (
                    <div className="time-scale-cell" key={`time-scale-${i}`}>
                        {index % 4 === 0 ? fifteenMinuteSliceToTime(index) : ''}
                    </div>
                );
            })}
        </div>
    );
}
