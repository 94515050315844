import { Activity } from '../../../constants/enums';
import { toTitleCase } from '../../../utils/string.util';

type LocationProps = {
    latitude: number;
    longitude: number;
    activity: Activity;
};

export function Location({ activity, latitude, longitude }: LocationProps) {
    return (
        <div>
            {toTitleCase(activity)} nearby ({latitude.toFixed(2)}, {longitude.toFixed(2)})
        </div>
    );
}
