import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { FaChevronDown, FaChevronRight } from 'react-icons/fa6';

type OptionsProps<T extends string | JSX.Element> = {
    itemKeyPrefix: string;
    customRender?: JSX.Element;
    label?: string | JSX.Element;
    options: T[];
    onOptionSelected: (option: T) => any;
    className?: string;
    hidden?: boolean;
};

export function Options<T extends string | JSX.Element>({
    itemKeyPrefix,
    customRender,
    label,
    options,
    onOptionSelected,
    className = '',
    hidden,
}: OptionsProps<T>) {
    function renderOption(option: T, index: number) {
        return (
            <MenuItem key={`${itemKeyPrefix}-${index}`}>
                <div
                    className="cursor-pointer px-2 py-1 hover:bg-secondary-hover"
                    onClick={() => onOptionSelected(option)}
                >
                    {option}
                </div>
            </MenuItem>
        );
    }

    return (
        <Menu as="div" hidden={hidden} className={`relative inline-block text-left ${className}`}>
            {customRender ? (
                <MenuButton>{customRender}</MenuButton>
            ) : (
                <MenuButton className="flex h-action min-w-full items-center justify-center rounded-md border border-text-color bg-secondary-action px-4 py-2 text-sm  font-semibold hover:bg-secondary-hover">
                    <div className="flex flex-row items-center">
                        <div className="flex flex-row">{label}</div>
                        <FaChevronDown size={10} className="ml-1" />
                    </div>
                </MenuButton>
            )}

            <MenuItems
                transition
                className="absolute right-0 z-10 mt-1 min-w-full origin-top-right rounded-md border bg-input-color shadow-xl ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
                <div className="py-1">{options.map(renderOption)}</div>
            </MenuItems>
        </Menu>
    );
}
