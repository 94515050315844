import { useMapsLibrary } from '@vis.gl/react-google-maps';
import { useEffect, useRef, useState } from 'react';
import { Input } from '../input';

interface PlaceAutocompleteProps {
    className?: string;
    onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void;
    placeholder?: string;
}

export const PlaceAutocomplete = ({
    onPlaceSelect,
    className = '',
    placeholder = '',
}: PlaceAutocompleteProps) => {
    const [placeAutocomplete, setPlaceAutocomplete] =
        useState<google.maps.places.Autocomplete | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const places = useMapsLibrary('places');

    useEffect(() => {
        if (!places || !inputRef.current) return;

        const options = {
            fields: ['geometry', 'name', 'formatted_address'],
        };

        setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
    }, [places]);

    useEffect(() => {
        if (!placeAutocomplete) return;

        placeAutocomplete.addListener('place_changed', () => {
            onPlaceSelect(placeAutocomplete.getPlace());
        });
    }, [onPlaceSelect, placeAutocomplete]);

    return (
        <Input
            inputRef={inputRef}
            className={`w-full bg-input-color pl-2 ${className}`}
            placeholder={placeholder}
        />
    );
};
