import { OpenPlayTimesDTO, PlaceDTO } from '../constants/entities.types';
import { post, get } from './requests';

const ROUTE = '/places';

export const createPlace = (place: PlaceDTO, openPlayTimesDTO: OpenPlayTimesDTO) =>
    post(
        ROUTE,
        { ...place, openPlayTimesDTO },
        {
            message: 'Failed to create place',
        }
    );

export const getPlaces = ({ latitude, longitude, activity, maximumDistance }) =>
    get(
        ROUTE,
        { latitude, longitude, activity, maximumDistance },
        { message: 'Failed to fetch places.' }
    );

export const getPlace = ({ placeId }: { placeId: string }) =>
    get(`${ROUTE}/${placeId}`, { message: 'Failed to fetch place.' });
