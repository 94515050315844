import { useEffect } from 'react';
import { NavigateFunction, useNavigate } from 'react-router';

type RerouteCallback = (navigate: NavigateFunction) => any;

export function useReroute(rerouteCallback: RerouteCallback, condition: boolean) {
    const navigate = useNavigate();

    useEffect(() => {
        if (condition) {
            rerouteCallback(navigate);
        }
    }, [condition]);
}
