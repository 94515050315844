import { useNavigate } from 'react-router';
import { OpenPlayTimesDTO, PlaceDTO } from '../../../constants/entities.types';
import { createPlace as createPlaceAPI } from '../../../api/place.api';
import { useDispatch } from 'react-redux';
import { messageActions } from '../../../stores/message.store';
import { Activity, MessageType } from '../../../constants/enums';
import { navigateToPlaces } from '../../../utils/route.utils';
import { useState } from 'react';

export const useCreatePlace = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const createPlace = (
        placeDTO: PlaceDTO,
        openPlayTimesDTO: OpenPlayTimesDTO,
        activity: Activity
    ) => {
        setIsLoading(true);
        createPlaceAPI(placeDTO, openPlayTimesDTO)
            .then(() => {
                navigateToPlaces(navigate, activity, {
                    latitude: undefined,
                    longitude: undefined,
                });
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    return {
        createPlace,
        isLoading,
    };
};
