import { FaX } from 'react-icons/fa6';
import { WEEKDAYS } from '../../../constants/constants';
import { OpenPlayTimesDTO } from '../../../constants/entities.types';
import { DayOfTheWeek } from '../../../constants/enums';
import { convertMinutesToTime, timeToMinutes } from '../../../utils/date.util';
import {
    HandleMouseDownFn,
    HandleMouseEnterCellFn,
    HandleTimeRemoveFn,
    TIME_SLOTS,
    TIME_SLOT_HEIGHT,
    TIME_SLOT_MINUTES,
} from '../calendar.constants';
import { EmptyCell } from './empty-cell';

type TimeSlotProps = {
    day: DayOfTheWeek;
    openPlayTimesDTO: OpenPlayTimesDTO;
    timeSlotIndex: number;
    draggingDay: DayOfTheWeek;
    handleTimeRemove: HandleTimeRemoveFn;
    isEdit: boolean;
};

function TimeSlot({
    day,
    timeSlotIndex,
    openPlayTimesDTO,
    draggingDay,
    handleTimeRemove,
    isEdit,
}: TimeSlotProps) {
    const openPlayTimes = openPlayTimesDTO[day];

    if (!openPlayTimes) {
        return undefined;
    }

    const key = `calendar-time-${day}-${timeSlotIndex}`;
    const timeSlotMinute = TIME_SLOT_MINUTES[timeSlotIndex];

    return (
        <>
            {openPlayTimes.map((openPlayTime, index) => {
                const { startTime, endTime, upVotes } = openPlayTime;
                const startTimeMinute = timeToMinutes(startTime);

                if (timeSlotMinute !== startTimeMinute) {
                    return undefined;
                }

                const endTimeMinute = timeToMinutes(endTime);
                const duration = endTimeMinute - startTimeMinute;
                const heightMultiplier = duration / 15;

                const formattedStartTime = convertMinutesToTime(startTimeMinute);
                const formattedEndTime = convertMinutesToTime(endTimeMinute);

                // Keep <section> to avoid div collision with css selector
                return (
                    <section
                        key={key}
                        className="calendar-time-slot-relative-container"
                        style={{
                            height: 0,
                            pointerEvents: draggingDay !== undefined ? 'none' : 'auto',
                        }}
                    >
                        <div
                            className="calendar-absolute-container"
                            style={{
                                height: `${TIME_SLOT_HEIGHT * heightMultiplier}px`,
                                zIndex: upVotes + 1,
                                pointerEvents: draggingDay !== undefined ? 'none' : 'auto',
                            }}
                        >
                            <div className="calendar-remove-x" hidden={!isEdit}>
                                <FaX size={12} onClick={() => handleTimeRemove(day, index)} />
                            </div>

                            <div className="calendar-time-slot">
                                <span>
                                    {formattedStartTime} - {formattedEndTime}
                                </span>
                            </div>
                        </div>
                    </section>
                );
            })}
        </>
    );
}

type TimeSlotsProps = {
    openPlayTimesDTO: OpenPlayTimesDTO;
    handleMouseEnterCell: HandleMouseEnterCellFn;
    handleMouseDown: HandleMouseDownFn;
    draggingDay: DayOfTheWeek;
    handleTimeRemove: HandleTimeRemoveFn;
    isEdit: boolean;
};

export function TimeSlots({
    openPlayTimesDTO,
    handleMouseEnterCell,
    handleMouseDown,
    draggingDay,
    handleTimeRemove,
    isEdit,
}: TimeSlotsProps) {
    return (
        <>
            {WEEKDAYS.map((day) => {
                return (
                    <div key={`calendar-col-${day}`} className="calendar-col">
                        {TIME_SLOTS.map((index) => {
                            return (
                                <>
                                    <EmptyCell
                                        handleMouseDown={handleMouseDown}
                                        handleMouseEnterCell={handleMouseEnterCell}
                                        day={day}
                                        timeSlotIndex={index}
                                    />
                                    <TimeSlot
                                        draggingDay={draggingDay}
                                        day={day}
                                        timeSlotIndex={index}
                                        openPlayTimesDTO={openPlayTimesDTO}
                                        handleTimeRemove={handleTimeRemove}
                                        isEdit={isEdit}
                                    />
                                </>
                            );
                        })}
                    </div>
                );
            })}
        </>
    );
}
