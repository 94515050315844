import { LeftSideActions } from './components/left-side-actions';
import { RightSideActions } from './components/right-side-actions';
import './header.css';

export function Header() {
    return (
        <div className="header mb-4 flex h-16 w-full items-center justify-center">
            <div className="flex h-16 w-app max-w-max-app flex-row items-center">
                <LeftSideActions />
                <RightSideActions />
                {/* <a className={ButtonClassName.Secondary}>Places</a> */}
            </div>
        </div>
    );
}
