import { Button, ButtonClassName } from '../../../components/button/button';
import { useNavigate, useParams } from 'react-router';
import { CREATE_PLACE_ROUTE } from '../../../constants/routes';
import { navigateToCreatePlace } from '../../../utils/route.utils';
import { useQueryCoords } from '../../../hooks/use-query-coords.hook';

export function NoPlaces() {
    const navigate = useNavigate();
    const { activity } = useParams();
    const { latitude, longitude } = useQueryCoords();

    function handleAddOnePressed() {
        navigateToCreatePlace(navigate, activity, { latitude, longitude });
    }

    return (
        <div className="flex flex-col items-center">
            <div className="mb-8 pt-4">No nearby places for {activity} found.</div>
            <Button onClick={handleAddOnePressed} className={ButtonClassName.Primary}>
                Add One
            </Button>
        </div>
    );
}
