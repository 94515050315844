import { useState, useEffect } from 'react';
import { getHistory } from '../../../api/history.api';
import { History } from '../../../constants/entities.types';

export function useFetchHistory() {
    const [histories, setHistories] = useState<History[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (histories.length === 0) {
            setIsLoading(true);
            getHistory()
                .then(({ history }) => {
                    setIsLoading(false);
                    if (history) {
                        const sortedHistory = history.sort((a, b) => b.createdAt - a.createdAt);
                        setHistories(sortedHistory);
                    }
                })
                .catch(() => {
                    setIsLoading(false);
                });
        }
    }, [histories.length]);

    return { histories, isLoading };
}
