import { Middleware } from 'redux';
import { messageActions } from '../stores/message.store';

const CLOSE_MESSAGE_IN_MS = 5000;
let timeout: NodeJS.Timeout;

export const messageMiddleware: Middleware = (_) => (next) => (action) => {
    // @ts-ignore
    if (action.type === messageActions.setMessage().type) {
        if (timeout) {
            clearTimeout(timeout);
        }
        setTimeout(() => {
            next(messageActions.closeMessage());
        }, CLOSE_MESSAGE_IN_MS);
    }
    next(action);
};
