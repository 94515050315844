import { Logo } from '../../components/logo/logo';
import { Activity } from '../../constants/enums';
import { navigateToLocation } from '../../utils/route.utils';
import { useNavigate } from 'react-router-dom';
import { Options } from '../../components/options/options';

export function ActivitySelect() {
    const navigate = useNavigate();

    const handleActivitySelected = (activity) => {
        navigateToLocation(navigate, activity);
    };

    return (
        <Logo>
            <div className="flex w-full justify-center">
                <Options
                    className="w-full max-w-48"
                    itemKeyPrefix="ActivityOption"
                    label="Select an activity"
                    options={Object.values(Activity)}
                    onOptionSelected={handleActivitySelected}
                />
            </div>
        </Logo>
    );
}
