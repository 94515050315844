import { Button, ButtonClassName } from '../../../components/button/button';
import { Card } from '../../../components/card/card';
import { Checkbox } from '../../../components/checkbox/checkbox';
import { NumberInput } from '../../../components/number-input/number-input';
import { Activity, CostType, FacilityType } from '../../../constants/enums';
import { EventFunction, PlaceResult } from '../../../constants/types';
import { getFacilityName } from '../../../utils/string.util';

type AddInformationProps = {
    activity: Activity;
    selectedPlace: PlaceResult;

    isFree: boolean | undefined;
    setIsFree: (v: boolean) => any;

    facilityCount: number | undefined;
    setFacilityCount: (v: number) => any;

    isInside: boolean | undefined;
    setIsInside: (v: boolean) => any;

    handleNextPressed: EventFunction;

    isPaid: boolean | undefined;
    setIsPaid: (v: boolean) => any;

    isOutside: boolean | undefined;
    setIsOutside: (v: boolean) => any;
};

export function AddInformation({
    activity,
    selectedPlace,
    isFree,
    setIsFree,
    facilityCount,
    setFacilityCount,
    isInside,
    setIsInside,
    handleNextPressed,
    isPaid,
    setIsPaid,
    isOutside,
    setIsOutside,
}: AddInformationProps) {
    const facilityName = getFacilityName(activity);

    const canNext =
        (isFree || isPaid) && (isOutside || isInside) && ![undefined, 0].includes(facilityCount);

    return (
        <Card header={selectedPlace?.name || ''} className="max-w-md ">
            <div className="h-full w-full pt-4">
                <section className="text-md mb-2">{facilityName} Count</section>
                <div className="mb-4">
                    <NumberInput
                        value={facilityCount}
                        onChange={setFacilityCount}
                        label="Facility Count"
                        placeholder="0"
                    />
                </div>

                <section className="text-md mb-2">{facilityName} Type</section>
                <div className="mb-4 flex flex-row items-center">
                    <Checkbox
                        className="mr-4"
                        isChecked={isInside}
                        onToggle={setIsInside}
                        label="Inside"
                    />
                    <Checkbox isChecked={isOutside} onToggle={setIsOutside} label="Outside" />
                </div>

                <section className="text-md mb-2">Cost</section>
                <div className="mb-4 flex flex-row items-center">
                    <Checkbox
                        className="mr-4"
                        isChecked={isPaid}
                        onToggle={setIsPaid}
                        label="Paid"
                    />
                    <Checkbox isChecked={isFree} onToggle={setIsFree} label="Free" />
                </div>

                <div className="flex items-center justify-center">
                    <Button
                        disabled={!canNext}
                        className={ButtonClassName.Primary}
                        onClick={handleNextPressed}
                    >
                        Next
                    </Button>
                </div>
            </div>
        </Card>
    );
}
