import { APIProvider } from '@vis.gl/react-google-maps';
import { GOOGLE_MAPS_API_KEY } from '../../constants/constants';

export function GoogleProvider({ children }) {
    return (
        <APIProvider apiKey={GOOGLE_MAPS_API_KEY} solutionChannel="">
            {children}
        </APIProvider>
    );
}
