import { Link } from 'react-router-dom';

type HeaderRouteProps = {
    route: string;
    label: string;
};

export function HeaderRoute({ route, label }: HeaderRouteProps) {
    return (
        <Link
            className="headerRoute flex h-full items-center justify-center pl-4 pr-4 text-center"
            to={route}
        >
            {label}
        </Link>
    );
}
