import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateOpenPlayTimes as updateOpenPlayTimesAPI } from '../../../api/open-play-time.api';
import { messageActions } from '../../../stores/message.store';
import { Activity, MessageType } from '../../../constants/enums';
import { OpenPlayTimesDTO } from '../../../constants/entities.types';

export const useUpdateOpenPlayTimes = () => {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const updateOpenPlayTimes = (
        placeId: string,
        activity: Activity,
        openPlayTimesDTO: OpenPlayTimesDTO
    ) => {
        setIsLoading(true);
        updateOpenPlayTimesAPI(placeId, activity, openPlayTimesDTO)
            .then(() => {
                dispatch(
                    messageActions.setMessage({
                        message: 'Successfully updated play times.',
                        messageType: MessageType.Success,
                    })
                );
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    return {
        updateOpenPlayTimes,
        isLoading,
    };
};
