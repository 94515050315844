import { MessageType } from '../../constants/enums';
import { useSelector } from '../../hooks/use-selector.hook';

const TEXT_MAP = {
    [MessageType.Error]: 'text-red-700',
    [MessageType.Success]: 'text-green-700',
    [MessageType.Warning]: 'text-yellow-700',
};

const BG_MAP = {
    [MessageType.Error]: 'bg-red-500',
    [MessageType.Success]: 'bg-green-500',
    [MessageType.Warning]: 'bg-yellow-500',
};

const BORDER_MAP = {
    [MessageType.Error]: 'border-red-400',
    [MessageType.Success]: 'border-green-400',
    [MessageType.Warning]: 'border-yellow-400',
};

export function Message() {
    const message = useSelector((state) => state.messageStore.message);
    const messageType = useSelector((state) => state.messageStore.messageType);

    const border = BORDER_MAP[messageType];
    const text = TEXT_MAP[messageType];
    const bg = BG_MAP[messageType];

    return (
        <div hidden={message === undefined} className="absolute bottom-5 left-5">
            <div className={`rounded-t ${bg} px-4 py-2 font-bold text-white`}>{messageType}</div>
            <div className={`rounded-b border border-t-0 ${border} bg-red-100 px-4 py-3 ${text}`}>
                <p>{message}</p>
            </div>
        </div>
    );
}
