import {
    AdvancedMarker,
    ControlPosition,
    Map,
    MapControl,
    useAdvancedMarkerRef,
} from '@vis.gl/react-google-maps';
import { PlaceAutocomplete } from './place-autocomplete';
import { MapHandler } from './map-handler';

type GoogleMapProps = {
    mapId: string;
    selectedPlace: google.maps.places.PlaceResult | undefined;
    setSelectedPlace: (param: google.maps.places.PlaceResult) => any;
    defaultLat?: number;
    defaultLng?: number;
};

const DEFAULT_LNG = -96;
const DEFAULT_LAT = 40;

export function GoogleMap({
    mapId,
    setSelectedPlace,
    selectedPlace,
    defaultLat = 40,
    defaultLng = -96,
}: GoogleMapProps) {
    const [markerRef, marker] = useAdvancedMarkerRef();

    defaultLat = defaultLat || DEFAULT_LAT;
    defaultLng = defaultLng || DEFAULT_LNG;

    return (
        <Map
            defaultZoom={4}
            defaultCenter={{ lat: defaultLat, lng: defaultLng }}
            gestureHandling={'greedy'}
            disableDefaultUI={true}
            mapId={mapId}
            // className="flex h-full flex-1"
        >
            <MapControl position={ControlPosition.TOP_CENTER}>
                <div className="flex-center flex w-72 items-center pt-8">
                    <PlaceAutocomplete
                        onPlaceSelect={setSelectedPlace}
                        placeholder="Search for a place"
                    />
                </div>
            </MapControl>
            <AdvancedMarker ref={markerRef} position={null} />
            <MapHandler place={selectedPlace} marker={marker} />
        </Map>
    );
}
