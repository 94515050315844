export function Logo({ className = 'flex flex-col justify-center', children }) {
    return (
        <div className={`${className} pt-8`}>
            <div className="mb-4 flex flex-col items-center justify-center">
                <img src="/favicon.ico" width={128} />
                <h2 className="mb-4 text-4xl font-medium">LocalPlay.io</h2>
                <h3 className="mb-4">Find nearby places and their open play times</h3>
            </div>
            {children}
        </div>
    );
}
