import { Link } from 'react-router-dom';
import { CONTACT_US_ROUTE, DISCORD_ROUTE } from '../../constants/routes';
import '../t&c/t&c.css';

export function PrivacyPolicy() {
    return (
        <div className="terms-and-conditions">
            <div className="t-a-c-header">LocalPlay.io Privacy Policy</div>
            <section>1. Information We Collect</section>
            <p>
                We collect two types of information from visitors to our website: a. Non-Personal
                Information: Crowdsourced Data: As part of our platform, users anonymously submit
                information about local play locations and times. No personal information is
                required to contribute this data. Usage Data: We may collect non-identifiable data
                about your visit to our website, such as your IP address, browser type,
                referring/exit pages, and the dates and times of access. This data is collected to
                improve site performance and user experience. b. Cookies and Tracking Technologies:
                We use cookies and similar technologies to enhance user experience and provide
                personalized services, including advertisements. Cookies are small text files stored
                on your device when you visit our website. You can control the use of cookies
                through your browser settings.
            </p>
            <section>2. Google AdSense and Cookies</section>
            <p>
                We use Google AdSense to display ads on our website. Google, as a third-party
                vendor, uses cookies to serve ads based on your prior visits to our site or other
                websites. DoubleClick Cookie: Google's DoubleClick cookie enables it and its
                partners to serve ads to you based on your visit to this site and other sites on the
                Internet. Opting Out of Personalized Ads: You can opt out of the use of the
                DoubleClick cookie for interest-based advertising by visiting the Google Ads
                Settings page. You can also visit the Network Advertising Initiative opt-out page to
                manage third-party advertising cookies. Please note that opting out of personalized
                ads will not prevent ads from being displayed; they just won't be tailored to your
                interests.
            </p>
            <section>3. How We Use the Information</section>
            <p>
                We use the information we collect in the following ways: To improve our website by
                analyzing site traffic and usage patterns. To display relevant ads through Google
                AdSense and other advertising partners. To enhance user experience and provide
                content based on collective user input.
            </p>
            <section>4. Third-Party Links</section>
            <p>
                Our website may contain links to third-party websites, such as advertisers or other
                resources. These third-party sites have their own privacy policies, and we are not
                responsible for their practices. We encourage you to review their policies when
                visiting these sites.
            </p>
            <section>5. Data Sharing and Disclosure</section>
            <p>
                We do not sell, trade, or otherwise transfer your personal information to third
                parties. However, we may share non-personal data, such as aggregated crowdsourced
                data or anonymized usage statistics, with partners or advertisers to help improve
                services and deliver targeted content.
            </p>
            <section>6. Security of Your Information</section>
            <p>
                We take reasonable precautions to protect the information we collect from loss,
                theft, misuse, and unauthorized access. However, no data transmission over the
                internet is completely secure, and we cannot guarantee the security of your data. By
                using our website, you acknowledge that you are sharing information at your own
                risk.
            </p>
            <section>7. Your Choices</section>
            <p>
                You have several options regarding the collection and use of your information:
                Cookies: You can manage cookies through your browser settings. You may choose to
                block cookies or receive a notification when cookies are being used. Please note
                that disabling cookies may affect the functionality of the website. Personalized
                Ads: You can opt out of personalized advertising as mentioned in the Google AdSense
                section above.
            </p>
            <section>9. Changes to This Privacy Policy</section>
            <p>
                We may update this Privacy Policy from time to time to reflect changes in our
                practices or applicable laws. We encourage you to review this policy periodically.
                The "Effective Date" at the top of this policy indicates when it was last updated.
            </p>
            <section>10. Contact Us</section>
            <p>
                If you have any questions or concerns about this Privacy Policy or how we handle
                your data, please contact through discord &nbsp;
                <a className="underline" href={DISCORD_ROUTE}>
                    here
                </a>
                &nbsp; or through the
                <Link className="underline" to={CONTACT_US_ROUTE}>
                    contact us form
                </Link>
            </p>
        </div>
    );
}
