import { useEffect } from 'react';
import { useSelector } from './use-selector.hook';
import { useDispatch } from 'react-redux';
import { localPlayActions } from '../stores/localplay.store';
import { v4 } from 'uuid';

export function useSetUserId() {
    const userId = useSelector((state) => state.localPlayStore.userId);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!userId) {
            dispatch(localPlayActions.setUserId({ userId: v4() }));
        }
    }, [userId]);
}
