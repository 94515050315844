import { Activity } from '../constants/enums';
import { NavigateFunction, createSearchParams } from 'react-router-dom';
import {
    CREATE_PLACE_ROUTE,
    LOCATION_ROUTE,
    OPEN_PLAY_TIMES_ROUTE,
    PLACES_ROUTE,
} from '../constants/routes';
import { Place } from '../constants/entities.types';

const setPathParam = (route: string, obj) => {
    Object.keys(obj).forEach((key) => {
        route = route.replace(`:${key}`, obj[key].toLowerCase());
    });
    return route;
};

export const navigateToLocation = (navigate: NavigateFunction, activity: Activity | string) => {
    const route = setPathParam(LOCATION_ROUTE, { activity });
    navigate(route);
};

function createQueryString(params) {
    return createSearchParams(params).toString();
}

export const navigateToCreatePlace = (
    navigate: NavigateFunction,
    activity: Activity | string,
    { latitude, longitude }
) => {
    const route = setPathParam(CREATE_PLACE_ROUTE, { activity });
    navigate({ pathname: route, search: createQueryString({ latitude, longitude }) });
};

export const navigateToOpenPlayTimes = (navigate: NavigateFunction, place: Place) => {
    const route = setPathParam(OPEN_PLAY_TIMES_ROUTE, { placeId: place.id });
    navigate(route);
};

export const navigateToPlaces = (
    navigate: NavigateFunction,
    activity: Activity | string,
    { latitude, longitude }
) => {
    const route = setPathParam(PLACES_ROUTE, { activity });
    navigate({
        pathname: route,
        search: createQueryString({ latitude, longitude }),
    });
};

export const to = (route: string) => (nav) => nav(route);
