import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LOCAL_PLAY_STORE } from '../constants/constants';

type LocalPlayStore = {
    maximumDistance: number;
    userId: string;
};

export const localPlayStore = createSlice({
    name: LOCAL_PLAY_STORE,
    initialState: {
        maximumDistance: 50,
    } as LocalPlayStore,
    reducers: {
        setUserId: (state, action: PayloadAction<{ userId: string }>) => {
            state.userId = action.payload.userId;
        },
        setMaximumDistance: (state, action: PayloadAction<{ maximumDistance: number }>) => {
            state.maximumDistance = action.payload.maximumDistance;
        },
    },
});

export const localPlayActions = localPlayStore.actions;
