import { Button, ButtonClassName } from '../../../components/button/button';
import { EventFunction } from '../../../constants/types';

type HeaderActionProps = {
    isEdit: boolean;
    handleEditPressed: EventFunction;
    handleSubmitPressed: EventFunction;
    isLoadingUpdate: boolean;
};

export function HeaderAction({
    isEdit,
    handleEditPressed,
    handleSubmitPressed,
    isLoadingUpdate,
}: HeaderActionProps) {
    if (!isEdit) {
        return (
            <Button className={ButtonClassName.Secondary} onClick={handleEditPressed}>
                Edit
            </Button>
        );
    }

    return (
        <Button
            className={ButtonClassName.Primary}
            onClick={handleSubmitPressed}
            isLoading={isLoadingUpdate}
        >
            Update
        </Button>
    );
}
