import { FaRegCircleQuestion } from 'react-icons/fa6';

type TooltipProps = {
    tooltip: string;
    iconSize?: number;
    tooltipTarget: string;
};

// @todo
export function Tooltip({ tooltip, iconSize, tooltipTarget }: TooltipProps) {
    return (
        <>
            <div className="inline-block">
                <FaRegCircleQuestion size={iconSize} data-tooltip-target={tooltipTarget} />
            </div>

            <div
                id={tooltipTarget}
                role="tooltip"
                className="tooltip invisible absolute z-10 inline-block rounded-lg bg-gray-900 px-3 py-2 text-sm font-medium text-white opacity-0 shadow-sm transition-opacity duration-300 dark:bg-gray-700"
            >
                {tooltip}
                <div className="tooltip-arrow" data-popper-arrow></div>
            </div>
        </>
    );
}
