type coordinatesCallback = (lat: number, lon: number) => any;
type errorCallback = (error: any) => any;

export function getGeolocation(
    coordinatesCallback: coordinatesCallback,
    errorCallback: errorCallback
) {
    function showPosition(position) {
        const { latitude, longitude } = position.coords;
        return coordinatesCallback(latitude, longitude);
    }

    function showError(error) {
        console.info({ error });
        switch (error.code) {
            case error.PERMISSION_DENIED:
                break;
            case error.POSITION_UNAVAILABLE:
                break;
            case error.TIMEOUT:
                break;
            case error.UNKNOWN_ERROR:
        }
        errorCallback(error);
    }

    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
        // @todo show message saying geolocation isn't support in browser
        return;
    }
}
