import { WEEKDAYS, WEEKDAY_TO_SHORT } from '../../../../constants/constants';
import { DayOfTheWeek } from '../../../../constants/enums';

type OpenPlayDayProps = {
    openPlayDays: DayOfTheWeek[];
};

function Day({ day, hasDay }: { day: DayOfTheWeek; hasDay: boolean }) {
    const dayClassName = hasDay ? 'border-green text-green' : 'border-disabled text-disabled';
    const label = WEEKDAY_TO_SHORT[day];

    return (
        <div className={`${dayClassName} mr-2 w-8 rounded-lg border-2 p-1 text-center text-sm`}>
            {label}
        </div>
    );
}

export function OpenPlayDays({ openPlayDays }: OpenPlayDayProps) {
    return (
        <div className="flex flex-row">
            {WEEKDAYS.map((day) => {
                const hasDay = openPlayDays?.includes(day) || false;
                return <Day day={day} hasDay={hasDay} />;
            })}
        </div>
    );
}
