import { InputRef } from '../constants/types';

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
    inputRef?: InputRef;
    handleOnChange?: (val) => any;
};

export const Input = ({
    className = '',
    handleOnChange,
    value,
    placeholder,
    inputRef,
    ...props
}: InputProps) => {
    const onChange = (event) => {
        handleOnChange?.(event.target.value);
    };

    return (
        <input
            className={`w-full rounded-lg border bg-input-color p-2.5 ${className}`}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            ref={inputRef}
            {...props}
        />
    );
};
