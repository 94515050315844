import dayjs from 'dayjs';
import { FooterLinks } from './components/footer-links';

export const Footer = () => {
    return (
        <div className="flex w-full items-center justify-center border-t-2 bg-background-color">
            <div className="flex w-full max-w-max-app flex-row p-4 text-dark-text-color">
                <div className="w-1/2">&copy; {dayjs().year()} OkayestDev</div>
                <div className="w-1/2">
                    <FooterLinks />
                </div>
            </div>
        </div>
    );
};
