import './loading-entities.css';

type LoadingEntitiesProps = {
    label: string;
};

export function LoadingEntities({ label }: LoadingEntitiesProps) {
    return (
        <div className="item-center flex w-full flex-col items-center justify-center">
            <div className="mb-6 text-lg">{label}</div>
            <div className="circle-loader" />
        </div>
    );
}
