import { useNavigate } from 'react-router';
import { ABOUT_ROUTE, ACTIVITY_SELECT_ROUTE, HISTORY_ROUTE } from '../../../constants/routes';
import { HeaderRoute } from './header-route';

export function LeftSideActions() {
    const navigate = useNavigate();

    return (
        <div className="flex h-full w-1/2 flex-row items-start">
            <div
                className="flex h-full cursor-pointer items-center justify-center"
                onClick={() => navigate(ACTIVITY_SELECT_ROUTE)}
            >
                <img src="/favicon.ico" width={48} />
            </div>

            <HeaderRoute route={ACTIVITY_SELECT_ROUTE} label="Search" />
            {/* <HeaderRoute route={STATS_ROUTE} label="Stats" /> */}
            <HeaderRoute route={HISTORY_ROUTE} label="History" />
            <HeaderRoute route={ABOUT_ROUTE} label="About" />
        </div>
    );
}
