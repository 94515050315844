export const ACTIVITY_SELECT_ROUTE = '/activity';
export const CREATE_PLACE_ROUTE = '/:activity/create-place';
export const LOCATION_ROUTE = '/:activity/find';
export const PLACES_ROUTE = '/:activity/places';
export const STATS_ROUTE = '/stats';
export const VOTES_ROUTE = '/votes';
export const HISTORY_ROUTE = '/history';
export const ABOUT_ROUTE = '/about';
export const OPEN_PLAY_TIMES_ROUTE = '/:placeId/open-play-times';
export const TERMS_AND_CONDITIONS_ROUTE = '/terms-and-conditions';
export const PRIVACY_POLICY_ROUTE = '/privacy-policy';
export const CONTACT_US_ROUTE = '/contact-us';

export const DISCORD_ROUTE = 'https://discord.gg/tXFqURsD';
export const PATREON_ROUTE = 'https://patreon.com/localplayio';
