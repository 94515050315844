import { OpenPlayTimesDTO } from '../constants/entities.types';
import { Activity } from '../constants/enums';
import { put } from './requests';

const ROUTE = '/open-play-times';

export const updateOpenPlayTimes = (
    placeId: string,
    activity: Activity,
    openPlayTimesDTO: OpenPlayTimesDTO
) =>
    put(
        `${ROUTE}`,
        {
            openPlayTimesDTO,
            placeId,
            activity,
        },
        {
            message: 'Failed to update open play times',
        }
    );
