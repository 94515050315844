import { padLeadingZero } from './string.util';
import dayjs from 'dayjs';

export const fifteenMinuteSliceToTime = (fifteenMinuteSlice: number) => {
    const hours = Math.floor(fifteenMinuteSlice / 4) % 12 || 12; // Special case for 12 a.m. and 12 p.m.
    const minutes = (fifteenMinuteSlice % 4) * 15;
    const period = Math.floor(fifteenMinuteSlice / 4) < 12 ? 'a.m.' : 'p.m.';

    const formattedMinutes = minutes.toString().padStart(2, '0');
    return `${hours}:${formattedMinutes} ${period}`;
};

export const timeToMinutes = (timeStr: string) => {
    const [hours, minutes] = timeStr.split(':').map(Number);
    return hours * 60 + minutes;
};

export const fifteenMinuteTo24HourFormat = (fifteenMinuteIndex: number) => {
    const hours = Math.floor(fifteenMinuteIndex / 4); // Each hour has 4 fifteen-minute blocks
    const minutes = (fifteenMinuteIndex % 4) * 15; // Remainder gives the minutes (0, 15, 30, 45)
    // Format the time as "H:m"
    return `${hours}:${padLeadingZero(minutes)}`;
};

export function convertMinutesToTime(minutes) {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;

    // Determine whether it's a.m. or p.m.
    const period = hours >= 12 ? 'p.m.' : 'a.m.';

    // Convert hours to 12-hour format
    const adjustedHours = hours % 12 || 12; // If hours == 0, show 12

    // Pad minutes with zero if less than 10
    const paddedMinutes = mins.toString().padStart(2, '0');

    return `${adjustedHours}:${paddedMinutes} ${period}`;
}

export function timestampToFormatted(timestamp: number) {
    return dayjs.unix(timestamp / 1000).format('MMMM D, YYYY h:mm A');
}
