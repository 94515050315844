type CardProps = {
    children: JSX.Element | string | JSX.Element[];
    header?: string | JSX.Element;
    className?: string;
    headerAction?: string | JSX.Element;
    headerClassName?: string;
};

export const Card = ({
    children,
    header,
    className = '',
    headerAction,
    headerClassName = '',
}: CardProps) => {
    return (
        <div
            className={`color flex w-full flex-col rounded bg-card-background p-4 shadow-lg ${className}`}
        >
            {/* <div className="overflow-auto"> */}
            <div className={`flex w-full flex-row ${headerClassName}`}>
                {header && <h1 className="mb-2 text-xl font-bold">{header}</h1>}
                {headerAction && <div className="flex flex-1 justify-end">{headerAction}</div>}
            </div>
            {children}
            {/* </div> */}
        </div>
    );
};
