import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { SelectAddress } from './components/select-address';
import { AddInformation } from './components/add-information';
import { PlaceResult } from '../../constants/types';
import { useBoolean } from '../../hooks/use-boolean.hook';
import { OpenPlayTimesDTO, PlaceDTO } from '../../constants/entities.types';
import { Activity } from '../../constants/enums';
import { navigateToPlaces } from '../../utils/route.utils';
import {
    grabPlaceResultFields,
    resolveCostTypes,
    resolveFacilityTypes,
} from '../../utils/place.util';
import { Button, ButtonClassName } from '../../components/button/button';
import { Tooltip } from '../../components/tooltip/tooltip';
import { Card } from '../../components/card/card';
import { useQueryCoords } from '../../hooks/use-query-coords.hook';
import { Calendar } from '../../components/calendar/calendar';
import { useCreatePlace } from './hooks/use-create-place.hook';

export function CreatePlaceView() {
    const { activity } = useParams();
    const navigate = useNavigate();

    const { latitude, longitude } = useQueryCoords();

    const [selectedPlace, setSelectedPlace] = useState<PlaceResult>(undefined);

    const [step, setStep] = useState(0);

    const [facilityCount, setFacilityCount] = useState(1);
    const [openPlayTimes, setOpenPlayTimes] = useState<OpenPlayTimesDTO>({});

    const { bool: isFree, handleToggle: toggleIsFree } = useBoolean(false);
    const { bool: isOutside, handleToggle: toggleIsOutside } = useBoolean(false);
    const { bool: isPaid, handleToggle: toggleIsPaid } = useBoolean(false);
    const { bool: isInside, handleToggle: toggleIsInside } = useBoolean(false);

    const { isLoading, createPlace } = useCreatePlace();

    function handleNextPressed() {
        // @todo ping api to see if location already exists for activity?
        setStep((step) => step + 1);
    }

    function handleSubmitPressed() {
        createPlace(
            {
                facilityCount,
                facilityTypes: resolveFacilityTypes(isOutside, isInside),
                costTypes: resolveCostTypes(isFree, isPaid),
                activity: activity as Activity,
                ...grabPlaceResultFields(selectedPlace),
            } as PlaceDTO,
            openPlayTimes,
            activity as Activity
        );
    }

    if (step === 0) {
        return (
            <SelectAddress
                selectedPlace={selectedPlace}
                setSelectedPlace={setSelectedPlace}
                handleNextPressed={handleNextPressed}
                defaultLat={latitude}
                defaultLng={longitude}
            />
        );
    }

    if (step === 1) {
        return (
            <AddInformation
                activity={activity as Activity}
                isFree={isFree}
                setIsFree={toggleIsFree}
                facilityCount={facilityCount}
                setFacilityCount={setFacilityCount}
                isInside={isInside}
                setIsInside={toggleIsInside}
                selectedPlace={selectedPlace}
                handleNextPressed={handleNextPressed}
                isPaid={isPaid}
                setIsPaid={toggleIsPaid}
                isOutside={isOutside}
                setIsOutside={toggleIsOutside}
            />
        );
    }

    if (step === 2) {
        return (
            <Card
                header={
                    <span>
                        Open Play Hours{' '}
                        <Tooltip tooltip="When do you play here?" tooltipTarget="openPlayHours" />
                    </span>
                }
                headerAction={
                    <Button
                        className={ButtonClassName.Primary}
                        onClick={handleSubmitPressed}
                        isLoading={isLoading}
                    >
                        Submit
                    </Button>
                }
            >
                <Calendar
                    openPlayTimesDTO={openPlayTimes}
                    setOpenPlayTimes={setOpenPlayTimes}
                    isEdit={true}
                />
            </Card>
        );
    }

    return undefined;
}
