import { EventFunction } from '../../constants/types';
import { FaRegSquare, FaRegSquareCheck } from 'react-icons/fa6';

type CheckboxProps = {
    isChecked: boolean | undefined;
    onToggle: EventFunction;
    label?: string;
    className?: string;
};

const ICON_CLASSNAME = 'bg-secondary-action hover:bg-secondary-hover rounded-sm text-text-color';

export function Checkbox({ isChecked, onToggle, label, className = '' }: CheckboxProps) {
    return (
        <>
            <div
                className={`flex cursor-pointer items-center hover:brightness-75 ${className}`}
                onClick={onToggle}
            >
                {isChecked ? (
                    <FaRegSquareCheck className={ICON_CLASSNAME} />
                ) : (
                    <FaRegSquare className={ICON_CLASSNAME} />
                )}
                {label && <label className="ml-1.5 cursor-pointer select-none">{label}</label>}
            </div>
        </>
    );
}
