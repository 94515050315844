export enum Activity {
    Pickleball = 'pickleball',
    // @todo add more
    // Tennis = 'tennis',
    // Basketball = 'basketball',
}

export enum MessageType {
    Error = 'Error',
    Warning = 'Warning',
    Success = 'Success',
}

export enum CostType {
    Free = 'Free',
    Paid = 'Paid',
}

export enum FacilityType {
    Outside = 'Outside',
    Inside = 'Inside',
}

export enum DayOfTheWeek {
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday',
    Sunday = 'Sunday',
}

export enum HistoryType {
    Change = 'Change',
    Addition = 'Addition',
    Vote = 'Vote',
}

export enum EntityType {
    Place = 'Place',
    OpenPlayTimes = 'OpenPlayTimes',
}
