import { Button, ButtonClassName } from '../../../components/button/button';
import { Card } from '../../../components/card/card';
import { GoogleMap } from '../../../components/google-map/google-map';
import { EventFunction, PlaceResult } from '../../../constants/types';

type SelectAddressProps = {
    selectedPlace: PlaceResult;
    setSelectedPlace: (place: PlaceResult) => any;
    handleNextPressed: EventFunction;
    defaultLat?: number;
    defaultLng?: number;
};

export function SelectAddress({
    selectedPlace,
    setSelectedPlace,
    handleNextPressed,
    defaultLat,
    defaultLng,
}: SelectAddressProps) {
    return (
        <>
            <div className="mb-4 flex w-full flex-row items-center">
                <div className="w-1/2 text-xl">Select a place and press next</div>
                <div className="flex w-1/2 justify-end">
                    <Button
                        onClick={handleNextPressed}
                        className={ButtonClassName.Primary}
                        disabled={selectedPlace === undefined}
                    >
                        Next
                    </Button>
                </div>
            </div>
            <Card className="h-700">
                <GoogleMap
                    mapId={'createPlaceMap'}
                    selectedPlace={selectedPlace}
                    setSelectedPlace={setSelectedPlace}
                    defaultLat={defaultLat}
                    defaultLng={defaultLng}
                />
            </Card>
        </>
    );
}
