import { useEffect, useState } from 'react';
import { OpenPlayTime, Place } from '../../../constants/entities.types';
import { getPlace } from '../../../api/place.api';

type Payload = {
    placeId: string;
};

export function useFetchOpenPlayTimes({ placeId }: Payload) {
    const [place, setPlace] = useState<Place>(undefined);
    const [openPlayTimes, setOpenPlayTimes] = useState<OpenPlayTime[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (placeId) {
            getPlace({ placeId })
                .then(({ place, openPlayTimes }) => {
                    setPlace(place);
                    setOpenPlayTimes(openPlayTimes);
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
        }
    }, [placeId]);

    return {
        place,
        openPlayTimes,
        isLoading,
    };
}
