import {
    OpenPlayTime,
    OpenPlayTimesDTO,
    OpenPlayTimesDTONested,
} from '../constants/entities.types';
import { DayOfTheWeek } from '../constants/enums';
import dayjs from 'dayjs';

export const toDTO = (openPlayTimes: OpenPlayTime[]): OpenPlayTimesDTO => {
    const openPlayTimeDTO = {};
    openPlayTimes.forEach((openPlayTime) => {
        const { dayOfTheWeek } = openPlayTime;

        if (!openPlayTimeDTO[dayOfTheWeek]) {
            openPlayTimeDTO[dayOfTheWeek] = [];
        }

        openPlayTimeDTO[dayOfTheWeek].push(openPlayTime);
    });
    return openPlayTimeDTO;
};

/** Merges over lapping start/end times into one block */
export function mergeOpenPlayTimes(openPlayTimesDTO: OpenPlayTimesDTO): OpenPlayTimesDTO {
    const mergedSlotsByDay: OpenPlayTimesDTO = {};

    const parseTime = (time: string): number => {
        const parts = time.split(':');
        const date = dayjs().hour(Number(parts[0])).minute(Number(parts[1]));
        return date.unix();
    };

    for (const day in openPlayTimesDTO) {
        const slots = openPlayTimesDTO[day];

        if (!slots || slots.length === 0) {
            continue;
        }

        const sortedSlots = slots.sort((a, b) => parseTime(a.startTime) - parseTime(b.startTime));

        const mergedSlots: Array<OpenPlayTimesDTONested> = [];

        let currentSlot: OpenPlayTimesDTONested | null = null;

        for (const slot of sortedSlots) {
            if (!currentSlot) {
                currentSlot = { ...slot };
                continue;
            }

            const currentEndTime = parseTime(currentSlot.endTime);
            const nextStartTime = parseTime(slot.startTime);
            const slotEndTime = parseTime(slot.endTime);

            if (currentEndTime >= nextStartTime) {
                currentSlot.endTime =
                    currentEndTime > slotEndTime ? currentSlot.endTime : slot.endTime;
                currentSlot.upVotes = (currentSlot.upVotes || 0) + (slot.upVotes || 0);
                currentSlot.downVotes = (currentSlot.downVotes || 0) + (slot.downVotes || 0);
            } else {
                mergedSlots.push(currentSlot);
                currentSlot = { ...slot };
            }
        }

        // Push the last slot if exists
        if (currentSlot) {
            mergedSlots.push(currentSlot);
        }

        // Assign merged slots to the current day
        mergedSlotsByDay[day as DayOfTheWeek] = mergedSlots;
    }

    return mergedSlotsByDay;
}
