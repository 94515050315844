import { DayOfTheWeek } from '../../../constants/enums';
import { HandleMouseDownFn, HandleMouseEnterCellFn } from '../calendar.constants';

type EmptyCellProps = {
    day: DayOfTheWeek;
    timeSlotIndex: number;
    handleMouseEnterCell: HandleMouseEnterCellFn;
    handleMouseDown: HandleMouseDownFn;
};

export function EmptyCell({
    day,
    timeSlotIndex,
    handleMouseDown,
    handleMouseEnterCell,
}: EmptyCellProps) {
    return (
        <div
            key={`calendar-empty-cell-${day}-${timeSlotIndex}`}
            className="calendar-cell"
            onMouseEnter={() => handleMouseEnterCell(day, timeSlotIndex)}
            onMouseDown={() => handleMouseDown(day, timeSlotIndex)}
        >
            {/* {(timeSlotIndex * 15) / 60} */}
        </div>
    );
}
