import { Activity, DayOfTheWeek } from './enums';

export const {
    REACT_APP_GOOGLE_MAPS_API_KEY: GOOGLE_MAPS_API_KEY,
    REACT_APP_LOCAL_PLAY_ENV: LOCAL_PLAY_ENV,
} = process.env;

// Stores
export const LOCAL_PLAY_STORE = 'localPlayStore';
export const USER_STORE = 'userStore';
export const MESSAGE_STORE = 'messageStore';

export const IS_LOCAL = LOCAL_PLAY_ENV === 'local';

export const API_HOST_MAP = {
    local: 'http://localhost:3005',
    staging: 'https://staging-api.localplay.io',
    production: 'https://api.localplay.io',
};

export const API_HOST = API_HOST_MAP[LOCAL_PLAY_ENV];

export const WEEKDAYS = [
    DayOfTheWeek.Monday,
    DayOfTheWeek.Tuesday,
    DayOfTheWeek.Wednesday,
    DayOfTheWeek.Thursday,
    DayOfTheWeek.Friday,
    DayOfTheWeek.Saturday,
    DayOfTheWeek.Sunday,
];

export const WEEKDAY_TO_SHORT = {
    [DayOfTheWeek.Monday]: 'M',
    [DayOfTheWeek.Tuesday]: 'T',
    [DayOfTheWeek.Wednesday]: 'W',
    [DayOfTheWeek.Thursday]: 'Th',
    [DayOfTheWeek.Friday]: 'F',
    [DayOfTheWeek.Saturday]: 'S',
    [DayOfTheWeek.Sunday]: 'Su',
};

export const WEEKDAY_TO_ABR = {
    [DayOfTheWeek.Monday]: 'Mon',
    [DayOfTheWeek.Tuesday]: 'Tue',
    [DayOfTheWeek.Wednesday]: 'Wed',
    [DayOfTheWeek.Thursday]: 'Thu',
    [DayOfTheWeek.Friday]: 'Fri',
    [DayOfTheWeek.Saturday]: 'Sat',
    [DayOfTheWeek.Sunday]: 'Sun',
};

export const ACTIVITY_TO_FACILITY_NAME = {
    [Activity.Pickleball]: 'court',
};

export const SK_SEPARATOR = '#';

export const METERS_IN_A_MILE = 1609.34;
