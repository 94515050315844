import { LoadingEntities } from '../../components/loading-entities/loading-entities';
import { HistoryComponent } from './components/history';
import { useFetchHistory } from './hooks/use-fetch-history.hook';

export function HistoryView() {
    const { isLoading, histories } = useFetchHistory();

    if (isLoading) {
        return <LoadingEntities label="Loading History" />;
    }

    return (
        <div className="w-full">
            {histories.map((history) => (
                <HistoryComponent history={history} />
            ))}
        </div>
    );
}
