const SECTION_CLASSNAME = 'mb-8 text-xl';

export function AboutView() {
    return (
        <div className="flex w-full flex-col items-center justify-center pt-2">
            <h1 className="mb-4 w-full border-b pb-2 text-center text-2xl">About LocalPlay.io</h1>
            <div className="w-4/6">
                <section className={SECTION_CLASSNAME}>
                    LocalPlay.io is a platform designed to help you find and share local spots for
                    playing sports like basketball, pickleball, and more. Whether you're a beginner
                    or a regular player, LocalPlay.io makes it easy to discover new places to play
                    and check for active time slots.
                </section>
                <section className={SECTION_CLASSNAME}>
                    LocalPlay.io helps you find the perfect spot to play by searching within a
                    nearby radius based on your location or provided address. Whether you're looking
                    for free public courts or paid facilities, the platform scans for places where
                    you can participate in your selected activity. From parks and community centers
                    to gyms and private clubs, LocalPlay.io gathers options that suit your needs.
                    Simply enter your activity of choice, and LocalPlay.io will show you a list of
                    available places nearby, so you can quickly find the best spot to play.
                </section>
                <section className={SECTION_CLASSNAME}>
                    With a focus on community-driven input, users can not only find great places but
                    also suggest and moderate locations themselves. Our self-moderation system
                    allows users to upvote and downvote spots and time slots, ensuring that the
                    information stays accurate and relevant.
                </section>
            </div>
        </div>
    );
}
