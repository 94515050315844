import { useState, useRef, useEffect } from 'react';
import { OpenPlayTimesDTO } from '../../constants/entities.types';
import { DayHeaders } from './components/day-headers';
import { TimeScale } from './components/time-scale';
import { TimeSlots } from './components/time-slots';
import './calendar.css';
import { DayOfTheWeek } from '../../constants/enums';
import { HandleCopyFn, HandleMouseDownFn, HandleMouseEnterCellFn } from './calendar.constants';
import { fifteenMinuteTo24HourFormat } from '../../utils/date.util';
import { removeIndex } from '../../utils/array.util';
import { useScroll } from '../../hooks/use-scroll.hook';
import { mergeOpenPlayTimes } from '../../utils/open-play-times.util';

type Props = {
    openPlayTimesDTO: OpenPlayTimesDTO;
    setOpenPlayTimes: (val: OpenPlayTimesDTO) => any;
    isEdit: boolean;
};

const SCROLL_OFFSET = 370;

export function Calendar({ openPlayTimesDTO, setOpenPlayTimes, isEdit }: Props) {
    const [draggingDay, setDraggingDay] = useState<DayOfTheWeek>(undefined);
    const [timeEditingIndex, setTimeEditingIndex] = useState<number>(0);
    const calendarContainerRef = useRef<HTMLDivElement>();

    useScroll(calendarContainerRef, SCROLL_OFFSET);

    const handleCopy: HandleCopyFn = (fromDay: DayOfTheWeek, toDay: DayOfTheWeek) => {
        openPlayTimesDTO[toDay] = openPlayTimesDTO[fromDay];
        setOpenPlayTimes({ ...openPlayTimesDTO });
    };

    const handleMouseUp = () => {
        if (!isEdit) {
            return;
        }

        setDraggingDay(undefined);
        setTimeEditingIndex(0);
        setOpenPlayTimes(mergeOpenPlayTimes(openPlayTimesDTO));
    };

    const handleMouseDown: HandleMouseDownFn = (day: DayOfTheWeek, timeSlotIndex: number) => {
        if (!isEdit) {
            return;
        }

        setDraggingDay(day);
        setTimeEditingIndex(openPlayTimesDTO[day]?.length || 0);
        // Minus one time index for a better feel
        const clickTimeIndex = timeSlotIndex > 0 ? timeSlotIndex - 1 : 0;
        const startTime = fifteenMinuteTo24HourFormat(clickTimeIndex);
        const endTime = fifteenMinuteTo24HourFormat(clickTimeIndex + 1);
        if (!openPlayTimesDTO[day]) {
            setOpenPlayTimes({
                ...openPlayTimesDTO,
                [day]: [
                    {
                        startTime,
                        endTime,
                    },
                ],
            });
            return;
        }

        openPlayTimesDTO[day].push({
            startTime,
            endTime,
        });
        setOpenPlayTimes({ ...openPlayTimesDTO });
    };

    function handleTimeRemove(day: DayOfTheWeek, index: number) {
        const dayTimeEntries = removeIndex(openPlayTimesDTO[day], index);
        setOpenPlayTimes({
            ...openPlayTimesDTO,
            [day]: dayTimeEntries,
        });
    }

    const handleMouseEnterCell: HandleMouseEnterCellFn = (
        day: DayOfTheWeek,
        timeSlotIndex: number
    ) => {
        if (!isEdit) {
            return;
        }

        if (draggingDay === day) {
            openPlayTimesDTO[day][timeEditingIndex] = {
                ...openPlayTimesDTO[day][timeEditingIndex],
                endTime: fifteenMinuteTo24HourFormat(timeSlotIndex),
            };
            setOpenPlayTimes({ ...openPlayTimesDTO });
            return;
        } else if (draggingDay !== undefined) {
            openPlayTimesDTO[day] = openPlayTimesDTO[day]
                ? removeIndex(openPlayTimesDTO[day], timeEditingIndex)
                : [];
            setOpenPlayTimes({ ...openPlayTimesDTO });
            return;
        }

        setDraggingDay(undefined);
    };

    return (
        <>
            <DayHeaders
                handleCopy={handleCopy}
                openPlayTimesDTO={openPlayTimesDTO}
                isEdit={isEdit}
            />
            <div
                ref={calendarContainerRef}
                className="calendar-container"
                onMouseUp={handleMouseUp}
            >
                <div className="calendar-height-container">
                    <TimeScale />
                    <TimeSlots
                        draggingDay={draggingDay}
                        openPlayTimesDTO={openPlayTimesDTO}
                        handleMouseEnterCell={handleMouseEnterCell}
                        handleMouseDown={handleMouseDown}
                        handleTimeRemove={handleTimeRemove}
                        isEdit={isEdit}
                        // handleMouseUp={handleMouseUp}
                    />
                </div>
            </div>
        </>
    );
}
