import { Loading } from '../loading/loading';
import './button.css';

type ButtonProps = {
    className?: string | ButtonClassName;
    children: string | JSX.Element;
    onClick: (event?: any) => any;
    disabled?: boolean;
    hidden?: boolean;
    isLoading?: boolean;
};

export enum ButtonClassName {
    Primary = 'btn-primary',
    Secondary = 'btn-secondary',
    Destructive = 'btn-destructive',
}

export const Button = ({
    hidden,
    className = '',
    children,
    onClick,
    disabled,
    isLoading,
}: ButtonProps) => {
    const handleOnClick = (...args) => {
        if (isLoading) {
            return;
        }
        onClick(...args);
    };

    return (
        <button hidden={hidden} className={className} onClick={handleOnClick} disabled={disabled}>
            {isLoading ? (
                <div className="px-2">
                    <Loading />
                </div>
            ) : (
                children
            )}
        </button>
    );
};
