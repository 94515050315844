import './divider.css';

type DividerProps = {
    label: string;
    maxWidth?: number;
    className?: string;
};

export function Divider({ label, maxWidth, className = '' }: DividerProps) {
    return (
        <div className={`${className} divider`} style={maxWidth ? { maxWidth } : {}}>
            <div className="dividerLine" />
            <div className="dividerText">{label}</div>
            <div className="dividerLine" />
        </div>
    );
}
