import axios from 'axios';
import { API_HOST, USER_STORE } from '../constants/constants';
import { store } from '../stores/store';
import { MessageType } from '../constants/enums';
import { messageActions } from '../stores/message.store';

const GET = 'GET';
const POST = 'POST';
const DELETE = 'DELETE';
const PUT = 'PUT';
const PATCH = 'PATCH';

export const createRoute = (apiRoute) => `${API_HOST}/${apiRoute}`;

export const getJwtFromState = () => {
    const state = store.getState();
    return state && state[USER_STORE] && state[USER_STORE].jwt ? state[USER_STORE].jwt : '';
};

type ErrorMessage = {
    messageType?: MessageType;
    message: string;
};

const DEFAULT_ERROR_MESSAGE: ErrorMessage = {
    messageType: MessageType.Error,
    message: 'Something went wrong.',
};

const backend = async (options, errorMessage: ErrorMessage) => {
    const jwt = getJwtFromState();
    return axios
        .request({
            baseURL: API_HOST,
            headers: {
                Authorization: jwt,
            },
            ...options,
        })
        .then((response) => {
            const data = response.data;

            if (data.error) {
                store.dispatch(
                    messageActions.setMessage({
                        message: data.error,
                        messageType: MessageType.Error,
                    })
                );
            }

            return data;
        })
        .catch((error) => {
            if (errorMessage.message) {
                store.dispatch(
                    messageActions.setMessage({
                        message: errorMessage.message,
                        messageType: errorMessage.messageType || MessageType.Error,
                    })
                );
            }
            throw error;
        });
};

export const get = async (route, params = {}, errorMessage = DEFAULT_ERROR_MESSAGE) => {
    return backend(
        {
            url: route,
            params,
            method: GET,
        },
        errorMessage
    );
};

export const post = async (route, params = {}, errorMessage = DEFAULT_ERROR_MESSAGE) => {
    return backend(
        {
            url: route,
            data: params,
            method: POST,
        },
        errorMessage
    );
};

export const put = async (route, params = {}, errorMessage = DEFAULT_ERROR_MESSAGE) => {
    return backend(
        {
            url: route,
            data: params,
            method: PUT,
        },
        errorMessage
    );
};

export const del = async (route, params = {}, errorMessage = DEFAULT_ERROR_MESSAGE) => {
    return backend(
        {
            url: route,
            data: params,
            method: DELETE,
        },
        errorMessage
    );
};

export const patch = async (route, params = {}, errorMessage = DEFAULT_ERROR_MESSAGE) => {
    return backend(
        {
            url: route,
            data: params,
            method: PATCH,
        },
        errorMessage
    );
};
