import { configureStore } from '@reduxjs/toolkit';
import { localPlayStore } from './localplay.store';
import { MESSAGE_STORE, LOCAL_PLAY_STORE } from '../constants/constants';
import { messageMiddleware } from '../middlewares/message.middleware';
import { messageStore } from './message.store';
import { loggerMiddleware } from '../middlewares/logger.middleware';

export const store = configureStore({
    reducer: {
        [LOCAL_PLAY_STORE]: localPlayStore.reducer,
        [MESSAGE_STORE]: messageStore.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(messageMiddleware).concat(loggerMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
