import { Input } from '../input';

type NumberInputProps = {
    onChange: (val: number) => any;
    value: number;
    label?: string;
    placeholder?: string;
};

export function NumberInput({ onChange, value, placeholder = '0' }: NumberInputProps) {
    return (
        <Input type="number" placeholder={placeholder} handleOnChange={onChange} value={value} />
    );
}
