import { METERS_IN_A_MILE, SK_SEPARATOR } from '../constants/constants';
import { Place, PlaceDTO } from '../constants/entities.types';
import { CostType, FacilityType } from '../constants/enums';
import { Coordinates, PlaceResult } from '../constants/types';
import { getDistance } from 'geolib';

export function resolveCostTypes(isFree: boolean, isPaid: boolean): CostType[] {
    const costTypes: CostType[] = [];

    if (isFree) {
        costTypes.push(CostType.Free);
    }

    if (isPaid) {
        costTypes.push(CostType.Paid);
    }

    return costTypes;
}

export function resolveFacilityTypes(isOutside: boolean, isInside: boolean): FacilityType[] {
    const facilityTypes: FacilityType[] = [];

    if (isInside) {
        facilityTypes.push(FacilityType.Inside);
    }

    if (isOutside) {
        facilityTypes.push(FacilityType.Outside);
    }

    return facilityTypes;
}

export function grabPlaceResultFields(placeResult: PlaceResult): Partial<PlaceDTO> {
    const { lng, lat } = placeResult.geometry.location;
    const { website, formatted_address, place_id, name } = placeResult;

    return {
        latitude: lat(),
        longitude: lng(),
        address: formatted_address,
        website: website,
        googlePlaceId: place_id,
        name,
    };
}

export function parseSk(sk: string): { latitude: number; longitude: number } {
    const [latitude, longitude] = sk.split(SK_SEPARATOR);
    return {
        latitude: parseFloat(latitude),
        longitude: parseFloat(longitude),
    };
}

export function getDistanceAway(place: Place, userCoords: Coordinates): number {
    const { latitude, longitude } = parseSk(place.sk);
    return getDistance({ latitude, longitude }, userCoords) / METERS_IN_A_MILE;
}
