import ReactDOM from 'react-dom/client';
import reportWebVitals from './report-web-vitals';
import { Router } from './router';
import { Provider } from 'react-redux';
import { store } from './stores/store';
import './css/index.css';

require('source-map-support/browser-source-map-support');
// @ts-ignore
window.sourceMapSupport.install();

const rootContainer = document.getElementById('root');

const root = ReactDOM.createRoot(rootContainer);

root.render(
    <Provider store={store}>
        <Router />
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(undefined);
